var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _vm.tag,
    { tag: "div", staticClass: "loading-overlay" },
    [
      _vm.failed
        ? _c("RetryFailed", {
            attrs: { loading: _vm.loading },
            on: { retry: _vm.retry },
          })
        : _vm._t("default"),
      _vm._v(" "),
      _vm.loading && !_vm.failed
        ? _c("div", { staticClass: "spinner-section" }, [_vm._m(0)])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "spinner-border text-muted" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }