var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Modal",
    { attrs: { title: "Submit request" }, on: { close: _vm.close } },
    [_c("RequestForm", { staticClass: "pt-2", on: { submit: _vm.submit } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }