var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "FormMultiSelect",
    _vm._g(
      _vm._b(
        { attrs: { value: _vm.values, options: _vm.options } },
        "FormMultiSelect",
        _vm.$attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }