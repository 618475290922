var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("FormLabel", {
        attrs: {
          for: _vm.id,
          label: _vm.label,
          help: _vm.help,
          required: _vm.required,
        },
      }),
      _vm._v(" "),
      _c(
        "input",
        _vm._b(
          {
            ref: "input",
            staticClass: "form-control",
            class: { "is-invalid": _vm.error },
            attrs: {
              id: _vm.id,
              type: _vm.type,
              required: _vm.required,
              readonly: _vm.readonly,
              autofocus: _vm.autofocus,
            },
            domProps: { value: _vm.formattedValue },
            on: { change: _vm.onChange },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _c("FormFeedback", { attrs: { error: _vm.error } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }