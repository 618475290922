var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("FormLabel", {
        attrs: {
          for: _vm.id,
          label: _vm.label,
          help: _vm.help,
          required: _vm.required,
        },
      }),
      _vm._v(" "),
      _c(
        "textarea",
        _vm._b(
          {
            staticClass: "form-control",
            class: { "is-invalid": _vm.error },
            attrs: { id: _vm.id, required: _vm.required },
            domProps: { value: _vm.value },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event.target.value)
              },
            },
          },
          "textarea",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _c("FormFeedback", { attrs: { error: _vm.error } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }