var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "form-control input-field",
      class: { "is-invalid": _vm.error },
      on: { click: _vm.focus },
    },
    [
      _vm.formattedValue
        ? _c("span", { staticClass: "truncated", class: _vm.customClass }, [
            _vm._v("\n        " + _vm._s(_vm.formattedValue) + "\n    "),
          ])
        : _c("span", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.placeholder)),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }