import { Axios, ToJson, UrlFactory } from "@gsx/common";
import {
    SpotlightBody,
    SpotlightListItem,
    SpotlightListQuery,
    SpotlightListResponse,
} from "@gsx/common/src/types/http/admin/spotlight";
import { adaptDate } from "../adapters";

const adaptRequest = (data: any): SpotlightListItem => ({
    ...data,
    submissionDate: adaptDate(data.submissionDate),
});

export class Spotlight {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/admin/spotlight");
    }

    public async list(params: SpotlightListQuery): Promise<SpotlightListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptRequest),
        };
    }

    public async create(body: Partial<ToJson<SpotlightBody>>, formData: FormData): Promise<void> {
        const url = this.urlFactory.create(`/`);
        await this.axios
            .post(url, body)
            .then(async (res) => this.uploadMedia(res.data.id, formData));
    }

    public async update(
        id: string,
        body: Partial<ToJson<SpotlightBody>>,
        formData: FormData,
    ): Promise<void> {
        const url = this.urlFactory.create(`/${id}`);
        await this.axios.put(url, body).then(async () => this.uploadMedia(id, formData));
    }

    public async get(id: string): Promise<SpotlightBody> {
        const url = this.urlFactory.create(`/${id}`);
        const response = await this.axios.get(url);
        return response.data as SpotlightBody;
    }

    public async delete(id: string): Promise<void> {
        const url = this.urlFactory.create(`/${id}`);
        await this.axios.delete(url);
    }

    private async uploadMedia(id: string, formData: FormData) {
        if (formData.has("picture") && formData.get("picture") !== "null") {
            const spotlightMediaUrl = this.urlFactory.create(`/${id}/media`);

            await this.axios.put(spotlightMediaUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        }
    }
}
