import { Organization } from "./Organization";
import { Conference } from "./Conference";
import { Statistics } from "./Statistics";
import { Report } from "./Report";
import { FlashNews } from "./FlashNews";
import { QuiltyInsights } from "./QuiltyInsights";
import { MergerAcquisitions } from "./MergerAcquisitions";
import { OrganizationMergerAcquisitions } from "./OrganizationMergerAcquisitions";
import { Subscription } from "./Subscription";
import { QuiltyResource } from "./QuiltyResource";
import { Spotlight } from "./Spotlight";

export class Dashboard {
    public constructor(
        public readonly organization: Organization,
        public readonly conference: Conference,
        public readonly statistics: Statistics,
        public readonly reports: Report,
        public readonly flashNews: FlashNews,
        public readonly spotlight: Spotlight,
        public readonly quiltyInsights: QuiltyInsights,
        public readonly mergerAcquisitions: MergerAcquisitions,
        public readonly organizationMergerAcquisitions: OrganizationMergerAcquisitions,
        public readonly subscription: Subscription,
        public readonly quiltyResources: QuiltyResource,
    ) {
        //
    }
}
