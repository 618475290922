var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "btn btn-primary provide-feedback",
      on: {
        click: function ($event) {
          return _vm.feedbackModalStore.open(
            _vm.FeedbackModalType.RecommendOrganization
          )
        },
      },
    },
    [
      _vm._v("\n    Recommend\n    "),
      _c("br"),
      _vm._v("\n    Organization\n    "),
      _c("br"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }