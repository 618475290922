var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.label
    ? _c("label", [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
        _vm.required
          ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
          : _vm._e(),
        _vm._v("\n     \n    "),
        _vm.help
          ? _c(
              "span",
              { staticClass: "form-help", attrs: { title: _vm.help } },
              [_c("i", { staticClass: "fe-icon-help-circle" })]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }