var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    _vm._g(
      _vm._b(
        {
          staticClass: "btn",
          attrs: { type: _vm.type, disabled: _vm.disabled || _vm.loading },
        },
        "button",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.loading
        ? _c("span", {
            staticClass: "spinner-border spinner-border-sm text-light mr-2",
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }