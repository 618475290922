var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.error
    ? _c("div", { staticClass: "invalid-feedback d-block" }, [
        _vm._v(_vm._s(_vm._f("capitalize")(_vm.error))),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }