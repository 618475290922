var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.label
        ? _c("FormLabel", {
            staticClass: "col-5 col-form-label",
            class: _vm.labelClass,
            attrs: { label: _vm.label, help: _vm.help, required: _vm.required },
            on: { click: _vm.focus },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.label ? "col-7" : "col-12" },
        [
          _vm.focused
            ? _c(
                "Treeselect",
                _vm._b(
                  {
                    ref: "input",
                    attrs: {
                      value: _vm.value,
                      options: _vm.options,
                      autoFocus: true,
                    },
                    on: { input: _vm.onInput, close: _vm.unfocus },
                  },
                  "Treeselect",
                  _vm.$attrs,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.focused && !_vm.type
            ? _c("InputField", {
                attrs: {
                  value: _vm.displayText,
                  error: _vm.error,
                  placeholder: _vm.placeholder,
                },
                on: { focus: _vm.focus },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.focused && _vm.type === "search"
            ? _c("div", { staticClass: "control has-icons-left w-100" }, [
                _c("input", {
                  staticClass: "form-control border-primary",
                  attrs: {
                    type: "text",
                    placeholder: _vm.placeholder,
                    autocomplete: "off",
                    autofocus: "",
                  },
                  on: { focus: _vm.focus },
                }),
                _vm._v(" "),
                _vm._m(0),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("FormFeedback", { attrs: { error: _vm.error } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }