var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("FormLabel", {
        attrs: { label: _vm.label, help: _vm.help, required: _vm.required },
        on: { click: _vm.focus },
      }),
      _vm._v(" "),
      _c(
        "Treeselect",
        _vm._g(
          _vm._b(
            { ref: "input", attrs: { value: _vm.value } },
            "Treeselect",
            _vm.$attrs,
            false
          ),
          _vm.listeners
        )
      ),
      _vm._v(" "),
      _c("FormFeedback", { attrs: { error: _vm.error } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }