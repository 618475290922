import { Api } from "./Api";
import { Admin } from "./admin/Admin";
import { Guest } from "./guest/Guest";
import { User } from "./user/User";
import { Dashboard } from "./dashboard/Dashboard";
import { Launchpad } from "./launchpad/Launchpad";
import { Organization as AdminOrganization } from "./admin/Organization";
import { OrganizationPastProject as AdminOrganizationPastProject } from "./admin/OrganizationPastProject";
import { OrganizationFile as AdminOrganizationFile } from "./admin/OrganizationFile";
import { PastProject as AdminPastProject } from "./admin/PastProject";
import { Feedback as AdminFeedback } from "./admin/Feedback";
import { Order as AdminOrder } from "./admin/Order";
import { Request as AdminRequest } from "./admin/Request";
import { User as AdminUser } from "./admin/User";
import { FlashNews as AdminFlashNews } from "./admin/FlashNews";
import { Spotlight as AdminSpotlight } from "./admin/Spotlight";
import { QuiltyInsights as AdminQuiltyInsights } from "./admin/QuiltyInsights";
import { Axios, UrlFactory } from "@gsx/common";
import { PastProject as UserPastProject } from "./user/PastProject";
import { Organization as GuestOrganization } from "./guest/Organization";
import { Statistics as GuestStatistics } from "./guest/Statistics";
import { Payment } from "./payment/Payment";
import { Organization as DashboardOrganization } from "./dashboard/Organization";
import { OrganizationResources as DashboardOrganizationResources } from "./dashboard/OrganizationResources";
import { Conference as DashboardConference } from "./dashboard/Conference";
import { Statistics as DashboardStatistics } from "./dashboard/Statistics";
import { UserFavorites as DashboardUserFavorites } from "./launchpad/UserFavorites";
import { ConferenceFavorites as DashboardUserFavoritesConference } from "./launchpad/ConferenceFavorites";
import { OrganizationFavorites as DashboardUserFavoritesOrganization } from "./launchpad/OrganizationFavorites";
import { AnalyticFavorites as DashboardUserFavoritesAnalytic } from "./launchpad/AnalyticFavorites";
import { UserActivity as DashboardUserActivity } from "./launchpad/UserActivity";
import { UserVisitedOrganizations as DashboardUserVisitedOrganzations } from "./launchpad/UserVisitedOrganizations";
import { Report as AdminReport } from "./admin/Report";
import { Report as DashboardReports } from "./dashboard/Report";
import { FlashNews as DashboardFlashNews } from "./dashboard/FlashNews";
import { Spotlight as DashboardSpotlight } from "./dashboard/Spotlight";
import { QuiltyInsights as DashboardQuiltyNews } from "./dashboard/QuiltyInsights";
import { MergerAcquisition as AdminMergerAcquisition } from "./admin/MergerAcquisition";
import { MergerAcquisitions as DashboardMergerAcquisitions } from "./dashboard/MergerAcquisitions";
import { OrganizationMergerAcquisitions as DashboardOrganizationMergerAcquisitions } from "./dashboard/OrganizationMergerAcquisitions";
import { Subscription as DashboardSubscription } from "../api/dashboard/Subscription";
import { QuiltyResource as AdminQuiltyResources } from "./admin/QuiltyResource";
import { QuiltyResource as DashboardQuiltyResource } from "../api/dashboard/QuiltyResource";

export class ApiFactory {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, apiUrl: string) {
        this.urlFactory = new UrlFactory(apiUrl);
    }

    public create(): Api {
        return new Api(
            new Admin(
                new AdminOrganization(
                    new AdminOrganizationPastProject(this.axios, this.urlFactory),
                    new AdminOrganizationFile(this.axios, this.urlFactory),
                    this.axios,
                    this.urlFactory,
                ),
                new AdminPastProject(this.axios, this.urlFactory),
                new AdminFeedback(this.axios, this.urlFactory),
                new AdminOrder(this.axios, this.urlFactory),
                new AdminRequest(this.axios, this.urlFactory),
                new AdminUser(this.axios, this.urlFactory),
                new AdminReport(this.axios, this.urlFactory),
                new AdminFlashNews(this.axios, this.urlFactory),
                new AdminSpotlight(this.axios, this.urlFactory),
                new AdminQuiltyInsights(this.axios, this.urlFactory),
                new AdminMergerAcquisition(this.axios, this.urlFactory),
                new AdminQuiltyResources(this.axios, this.urlFactory),
            ),
            new User(new UserPastProject(this.axios, this.urlFactory), this.axios, this.urlFactory),
            new Guest(
                new GuestOrganization(this.axios, this.urlFactory),
                new GuestStatistics(this.axios, this.urlFactory),
                this.axios,
                this.urlFactory,
            ),
            new Payment(this.axios, this.urlFactory),
            new Dashboard(
                new DashboardOrganization(
                    new DashboardOrganizationResources(this.axios, this.urlFactory),
                    this.axios,
                    this.urlFactory,
                ),
                new DashboardConference(this.axios, this.urlFactory),
                new DashboardStatistics(this.axios, this.urlFactory),
                new DashboardReports(this.axios, this.urlFactory),
                new DashboardFlashNews(this.axios, this.urlFactory),
                new DashboardSpotlight(this.axios, this.urlFactory),
                new DashboardQuiltyNews(this.axios, this.urlFactory),
                new DashboardMergerAcquisitions(this.axios, this.urlFactory),
                new DashboardOrganizationMergerAcquisitions(this.axios, this.urlFactory),
                new DashboardSubscription(this.axios, this.urlFactory),
                new DashboardQuiltyResource(this.axios, this.urlFactory),
            ),
            new Launchpad(
                new DashboardUserFavorites(
                    new DashboardUserFavoritesConference(this.axios, this.urlFactory),
                    new DashboardUserFavoritesOrganization(this.axios, this.urlFactory),
                    new DashboardUserFavoritesAnalytic(this.axios, this.urlFactory),
                ),
                new DashboardUserActivity(this.axios, this.urlFactory),
                new DashboardUserVisitedOrganzations(this.axios, this.urlFactory),
            ),
        );
    }
}
