var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "btn btn-primary provide-feedback",
      on: {
        click: function ($event) {
          return _vm.feedbackModalStore.open(_vm.FeedbackModalType.Feedback)
        },
      },
    },
    [_vm._v("\n    Provide\n    "), _c("br"), _vm._v("\n    Feedback\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }