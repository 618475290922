import { Feedback } from "./Feedback";
import { Order } from "./Order";
import { Request } from "./Request";
import { Organization } from "./Organization";
import { PastProject } from "./PastProject";
import { User } from "./User";
import { Report } from "./Report";
import { FlashNews } from "./FlashNews";
import { Spotlight } from "./Spotlight";
import { QuiltyInsights } from "./QuiltyInsights";
import { MergerAcquisition } from "./MergerAcquisition";
import { QuiltyResource } from "./QuiltyResource";

export class Admin {
    public constructor(
        public readonly organization: Organization,
        public readonly pastProject: PastProject,
        public readonly feedback: Feedback,
        public readonly order: Order,
        public readonly request: Request,
        public readonly user: User,
        public readonly reports: Report,
        public readonly flashNews: FlashNews,
        public readonly spotlight: Spotlight,
        public readonly quiltyInsights: QuiltyInsights,
        public readonly mergerAcquisition: MergerAcquisition,
        public readonly quiltyResources: QuiltyResource,
    ) {
        //
    }
}
