var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "custom-control custom-checkbox" }, [
    _c("input", {
      staticClass: "custom-control-input",
      attrs: { id: _vm.id, type: "checkbox" },
      domProps: { checked: _vm.value },
      on: { change: _vm.onChange },
    }),
    _vm._v(" "),
    _c(
      "label",
      { staticClass: "custom-control-label", attrs: { for: _vm.id } },
      [
        _vm._t("label", function () {
          return [_vm._v("\n            " + _vm._s(_vm.label) + "\n        ")]
        }),
        _vm._v(" "),
        _vm.required
          ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }