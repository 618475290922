var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("small", { staticClass: "text-muted" }, [
      _vm._v("\n    This site is protected by reCAPTCHA and the Google\n    "),
      _c(
        "a",
        {
          attrs: {
            href: "https://policies.google.com/privacy",
            target: "_blank",
          },
        },
        [_vm._v("Privacy Policy")]
      ),
      _vm._v("\n    and\n    "),
      _c(
        "a",
        {
          attrs: {
            href: "https://policies.google.com/terms",
            target: "_blank",
          },
        },
        [_vm._v("Terms of Service")]
      ),
      _vm._v("\n    apply.\n"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }