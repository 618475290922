var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "card pricing-card price-box border border-primary",
      staticStyle: { margin: "0 auto" },
    },
    [
      _c(
        "div",
        { staticClass: "card-body text-center" },
        [
          _vm._t("header"),
          _vm._v(" "),
          _c("h5", { staticClass: "pt-3" }, [_vm._v(_vm._s(_vm.name))]),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }