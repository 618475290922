var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitFeedback.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "Modal",
        {
          attrs: { title: "Submit feedback" },
          on: { close: _vm.feedbackModalStore.close },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "ActionButton",
                    {
                      staticClass: "btn-primary btn-sm",
                      attrs: {
                        type: "submit",
                        loading: _vm.submitFeedbackStore.isLoading,
                      },
                    },
                    [_vm._v("\n                Submit Feedback\n            ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("h3", { staticClass: "pb-1" }, [
                _vm._v("For support please contact us at:"),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass: "d-flex flex-column pl-1",
                  staticStyle: { "list-style": "none", gap: "4px" },
                },
                [
                  _c("li", [
                    _c("h5", [
                      _vm._v(
                        "\n                            Email:\n                            "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "mailto:support@globalspaceexchange.com",
                          },
                        },
                        [
                          _vm._v(
                            "\n                                support@globalspaceexchange.com\n                            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c(
                  "FormSelect",
                  {
                    attrs: {
                      customClass: "text-capitalize",
                      label: "Request Type",
                      error: _vm.errors.type,
                      required: "",
                    },
                    model: {
                      value: _vm.values.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "type", $$v)
                      },
                      expression: "values.type",
                    },
                  },
                  _vm._l(_vm.feedbackTypes, function (item) {
                    return _c("option", { domProps: { value: item } }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item) +
                          "\n                    "
                      ),
                    ])
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("FormInput", {
                  attrs: {
                    label: _vm.titleTitle,
                    error: _vm.errors.title,
                    required: "",
                    autofocus: "",
                  },
                  model: {
                    value: _vm.values.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.values, "title", $$v)
                    },
                    expression: "values.title",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.values.type === _vm.getRecommendType
              ? _c(
                  "div",
                  { staticClass: "col-sm-12" },
                  [
                    _c(
                      "FormSelect",
                      {
                        attrs: {
                          label: "Requesting For",
                          error: _vm.errors.requestingFor,
                          required: "",
                        },
                        model: {
                          value: _vm.values.requestingFor,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "requestingFor", $$v)
                          },
                          expression: "values.requestingFor",
                        },
                      },
                      [
                        _c("option", [_vm._v("My organization")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("Another organization")]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("FormTextarea", {
                  attrs: {
                    label: "Description",
                    rows: "5",
                    error: _vm.errors.description,
                    required: "",
                  },
                  model: {
                    value: _vm.values.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.values, "description", $$v)
                    },
                    expression: "values.description",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("FormInput", {
                  attrs: {
                    label: "Page triggered",
                    error: _vm.errors.pageTriggered,
                    required: "",
                    readonly: "",
                  },
                  model: {
                    value: _vm.values.pageTriggered,
                    callback: function ($$v) {
                      _vm.$set(_vm.values, "pageTriggered", $$v)
                    },
                    expression: "values.pageTriggered",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [_c("RecaptchaDisclosure")],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }