var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group tel-group" },
    [
      _c("FormLabel", {
        attrs: {
          for: _vm.id,
          label: _vm.label,
          help: _vm.help,
          required: _vm.required,
        },
      }),
      _vm._v(" "),
      _c("vue-tel-input", {
        attrs: {
          inputId: _vm.id,
          inputClasses: _vm.inputClasses,
          wrapperClasses: _vm.wrapperClasses,
          value: _vm.initValue,
          inputOptions: { showDialCode: true },
          preferredCountries: ["US"],
          required: _vm.required,
          readonly: _vm.readonly,
        },
        on: { input: _vm.onInput },
      }),
      _vm._v(" "),
      _c("FormFeedback", { attrs: { error: _vm.error } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }