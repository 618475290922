var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("tr", [
    _c(
      "td",
      { staticClass: "text-center p-5", attrs: { colspan: _vm.colspan } },
      [_vm._v("No records")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }