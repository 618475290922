var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-open" }, [
    _c(
      "div",
      { staticClass: "modal fade show", staticStyle: { display: "block" } },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column",
                  staticStyle: { "flex-basis": "100%" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-space-between",
                    },
                    [
                      _c("h4", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                      _vm._v(" "),
                      _vm._t("header"),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: { type: "button" },
                          on: { click: _vm.close },
                        },
                        [_c("span", [_vm._v("×")])]
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm._t("header-row"),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body pt-3" },
              [_vm._t("default")],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "modal-backdrop fade show",
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.close.apply(null, arguments)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }