var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "retry-box" },
    [
      _c("h4", { staticClass: "mb-4 text-danger" }, [
        _vm._v("Something went wrong"),
      ]),
      _vm._v(" "),
      _c(
        "ActionButton",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", loading: _vm.loading },
          on: { click: _vm.retry },
        },
        [_vm._v("\n        Try again\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }