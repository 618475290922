import { Axios, UrlFactory } from "@gsx/common";
import {
    SpotlightBody,
    SpotlightListItem,
    SpotlightListQuery,
    SpotlightListResponse,
} from "@gsx/common/src/types/http/dashboard/spotlight";
import { adaptDate } from "../adapters";

const adaptRequest = (data: any): SpotlightListItem => ({
    ...data,
    submissionDate: adaptDate(data.submissionDate),
});

export class Spotlight {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/dashboard/spotlight");
    }

    public async list(params: SpotlightListQuery): Promise<SpotlightListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptRequest),
        };
    }

    public async get(id: string): Promise<SpotlightBody> {
        const url = this.urlFactory.create(`/${id}`);
        const response = await this.axios.get(url);
        return response.data as SpotlightBody;
    }

    public async toggle(id: string): Promise<SpotlightBody> {
        const url = this.urlFactory.create(`/${id}/like`);
        const response = await this.axios.post(url);
        return response.data as SpotlightBody;
    }
}
