var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("FormLabel", {
        attrs: { label: _vm.label, help: _vm.help, required: _vm.required },
        on: { click: _vm.focus },
      }),
      _vm._v(" "),
      _c(
        "Multiselect",
        _vm._g(
          _vm._b(
            {
              ref: "select",
              class: _vm.customClass,
              attrs: {
                "track-by": "id",
                label: "name",
                closeOnSelect: !_vm.multiple,
                showLabels: false,
                value: _vm.formattedValue,
                options: _vm.formattedOptions,
                multiple: _vm.multiple,
                required: _vm.required,
                placeholder: _vm.placeholder,
                "tag-placeholder": "Add new item",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "option",
                    fn: function ({ option }) {
                      return [
                        _c(
                          "div",
                          [
                            _c("strong", [_vm._v(_vm._s(option.name))]),
                            _vm._v(" "),
                            option.secondaryText
                              ? _c(
                                  "span",
                                  { staticStyle: { "font-size": "14px" } },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(option.secondaryText) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._t("attributes", null, { option: option }),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                  _vm.noResultText
                    ? {
                        key: "noResult",
                        fn: function () {
                          return [
                            _c("span", [_vm._v(_vm._s(_vm.noResultText))]),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            "Multiselect",
            _vm.$attrs,
            false
          ),
          _vm.listeners
        )
      ),
      _vm._v(" "),
      _c("FormFeedback", { attrs: { error: _vm.error } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }