var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "Modal",
        {
          attrs: { title: "Subscription Redirect" },
          on: { close: _vm.modalStore.close },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "ActionButton",
                    {
                      staticClass: "btn-outline-primary btn-sm",
                      attrs: { type: "button" },
                      on: { click: _vm.modalStore.close },
                    },
                    [_vm._v("\n                Cancel\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ActionButton",
                    {
                      staticClass: "btn-primary btn-sm",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("Subscribe")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("h4", { staticClass: "col-12" }, [
              _vm._v(
                "\n                Purchase an Individual Subscription to access more Space Insights.\n            "
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }